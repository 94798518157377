<template>
  <main style="background-color: #fbfaf3">
    <nav class="navbar navbar-expand-lg bg-light navbar-light nav-desk">
      <div class="container-fluid">
        <a class="navbar-brand" href="/">
          <img src="/images/logo_final.webp" alt="logo" width="300" class="d-inline-block align-text-center" loading="lazy" decoding="async" />
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
            <li class="nav-item">
              <router-link class="nav-item text-decoration-none" to="/signin">
                <span class="nav-link color-link">Regístrate</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-item text-decoration-none nav-link color-link" to="/login"> Iniciar Sesión </router-link>
            </li>
            <li class="nav-item">
              <a class="nav-item text-decoration-none nav-link color-link d-flex align-items-center" href="https://www.tienda.mundouniversitario.com" target="_blank">
                <span>Tienda</span>
                <img src="/images/logo_store.svg" alt="Tienda" style="width: 60px; height: 60px; " />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <nav class="navbar navbar-expand-lg bg-light navbar-light fixed-top nav-mobile">
      <div class="container-fluid">
        <a class="navbar-brand" href="/">
          <img src="/images/logo_final.png" alt="logo" width="300" class="d-inline-block align-text-center" loading="lazy" decoding="async" />
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link class="nav-item text-decoration-none" to="/signin">
                <span class="nav-link color-link">Regístrate</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-item text-decoration-none nav-link color-link" to="/login"> Iniciar Sesión </router-link>
            </li>
            <li class="nav-item">
              <a class="nav-item text-decoration-none nav-link color-link d-flex align-items-center" href="https://www.tienda.mundouniversitario.com" target="_blank">
                <span>Tienda</span>
                <img src="/images/logo_store.svg" alt="Tienda" style="width: 60px; height: 60px; " />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <section class="container-after-down">
      <div class="container-text-principal">
        <div class="container-text-principal-left">
          <p class="text-principal-left mt-5">Estudiantes</p>
        </div>
        <div class="container-layout">
          <div>
            <p class="title-text-center">
              La Bolsa Más Grande<br />
              De Universitarios
            </p>
          </div>
          <div>
            <div style="display: flex; flex-direction: column">
              <div style="display: flex; justify-content: space-evenly; align-items: center; margin-bottom: 2rem">
                <div>
                  <button class="button-home" @click="viewregister()">Comenzar</button>
                </div>
                <div>
                  <button class="button-home" @click="viewregister2()">Buscar Vacantes</button>
                </div>
              </div>
              <p style="font-family: Poppins; color: white; font-size: 28px; text-align: center">
                Conoce más acerca de nuestras vacantes<br />
                para estudiantes y profesionistas
              </p>
            </div>
          </div>
        </div>
        <div class="container-image-right">
          <img :src="'/images/izquierdo.webp'" loading="lazy" decoding="async" class="image-central" style="width: 60%" alt="Responsive image" />
        </div>
      </div>
      <div class="container-layout-hidden">
        <p class="title-text-center-hidden">
          La Bolsa Más Grande<br />
          De Universitarios
        </p>
        <div>
          <div style="display: flex; flex-direction: column">
            <div style="display: flex; justify-content: center; align-items: center; margin-bottom: 2rem">
              <div>
                <button class="button-home" @click="viewregister()">Comenzar</button>
              </div>
              <div>
                <button class="button-home" @click="viewregister2()">Buscar Vacantes</button>
              </div>
            </div>
            <p class="text-hidden" style="">
              Conoce más acerca de nuestras vacantes<br />
              para estudiantes y profesionales
            </p>
          </div>
        </div>
      </div>
      <div class="container-image">
        <div class="container-text-principal-left">
          <p class="text-principal-left mt-5">Profesionistas</p>
        </div>
        <div class="container-image-left">
          &nbsp;
          <img :src="'/images/recortada.webp'" lazy="eager" class="image-central" style="width: 60%" alt="profesionales" />
        </div>
      </div>
    </section>
    <footer id="footer">
      <div class="container-social-red">
        <a class="footer-icon" href="https://www.facebook.com/Mundo-Universitario-110567257738778 " aria-label="Link Facebook" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(255, 255, 255, 1);">
            <path
              d="M12.001 2.002c-5.522 0-9.999 4.477-9.999 9.999 0 4.99 3.656 9.126 8.437 9.879v-6.988h-2.54v-2.891h2.54V9.798c0-2.508 1.493-3.891 3.776-3.891 1.094 0 2.24.195 2.24.195v2.459h-1.264c-1.24 0-1.628.772-1.628 1.563v1.875h2.771l-.443 2.891h-2.328v6.988C18.344 21.129 22 16.992 22 12.001c0-5.522-4.477-9.999-9.999-9.999z"></path>
          </svg>
        </a>
        <a class="footer-icon" href="https://www.youtube.com/channel/UCjLhaKc3Qv1pYxIe9kaaehg" aria-label="Link Youtube" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(255, 255, 255, 1);">
            <path
              d="M21.593 7.203a2.506 2.506 0 0 0-1.762-1.766C18.265 5.007 12 5 12 5s-6.264-.007-7.831.404a2.56 2.56 0 0 0-1.766 1.778c-.413 1.566-.417 4.814-.417 4.814s-.004 3.264.406 4.814c.23.857.905 1.534 1.763 1.765 1.582.43 7.83.437 7.83.437s6.265.007 7.831-.403a2.515 2.515 0 0 0 1.767-1.763c.414-1.565.417-4.812.417-4.812s.02-3.265-.407-4.831zM9.996 15.005l.005-6 5.207 3.005-5.212 2.995z"></path>
          </svg>
        </a>
        <a class="footer-icon" href="https://www.instagram.com/mundoo_universitario/" aria-label="Link Instagram" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(255, 255, 255, 1);">
            <path d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248 4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008 3.004 3.004 0 0 1 0 6.008z"></path>
            <circle cx="16.806" cy="7.207" r="1.078"></circle>
            <path
              d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42 4.6 4.6 0 0 0-2.633 2.632 6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419 4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688 2.987 2.987 0 0 1-1.712 1.711 4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311 2.985 2.985 0 0 1-1.719-1.711 5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654 0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311 2.991 2.991 0 0 1 1.712 1.712 5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011z"></path>
          </svg>
        </a>
        <a class="footer-icon" href="https://www.linkedin.com/company/mundo-universitario/" aria-label="Link Linkedin" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(255, 255, 255, 1);">
            <circle cx="4.983" cy="5.009" r="2.188"></circle>
            <path
              d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"></path>
          </svg>
        </a>
      </div>
      <div class="container-text">
        <!-- <div>
          <a href="tel:+525568450578" style="text-decoration: none">
            <small class="footer-text">5568450578</small>
          </a>
        </div> -->
        <div>
          <a rel="noreferrer" style="text-decoration: none" href="mailto:soporte@mundouniversitario.mx"><small class="footer-text">soporte@mundouniversitario.mx</small></a>
        </div>

        <router-link to="/avisodeprivacidad" class="footer-text">
          <small>Aviso De Privacidad</small>
        </router-link>
        <div>
          <small class="footer-text">Talento Universitario {{ new Date().getFullYear() }} </small>
        </div>
      </div>
    </footer>
    <button type="button" class="btn fixed-button">
      <a style="display: flex; justify-content: center; align-items: center; color: white; gap: 4px;" href="http://wa.me/5637526810" target="_blank">
        <box-icon name='whatsapp' type='logo' color="white" size="md"></box-icon>
      </a>
    </button>
  </main>
</template>
<script lang="js">
import { defineComponent } from "vue";



export default defineComponent({
  data: () => ({
    imageUrl: "/images/profile.jpg",
  }),
  created() {
    localStorage.clear();
  },
  methods: {

    viewregister() {
      this.$router.push('/signin')
    },
    viewregister2() {
      this.$router.push('/home/vacantes')
    },

  }


});
</script>
<style land="scss">
@import "../styles/home.scss";
</style>
